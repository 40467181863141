import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import WhiteHeader from '../WhiteHeader';

const FallbackView = ({
  Icon,
  icon,
  text,
  title,
  btnText,
  isShowHeader,
  onClickHandler,
  ...rest
}) => {
  return (
    <>
      {isShowHeader && <WhiteHeader />}
      <div className="fallback-view" data-cy="fallback-view">
        <div className="fallback-view__icon">
          {icon ? (
            <Image alt="" src={icon} {...rest} />
          ) : (
            <Icon alt="" {...rest} />
          )}
        </div>
        <div className="fallback-view__title">{title}</div>
        <div className="fallback-view__text">{text}</div>
        <Link id="home-link" className="new-search__link" href={`/`}>
          <button
            type="button"
            className="fallback-view__btn"
            onClick={onClickHandler}
          >
            {btnText}
          </button>
        </Link>
      </div>
    </>
  );
};

FallbackView.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
  isShowHeader: PropTypes.bool,
  onClickHandler: PropTypes.func,
  btnText: PropTypes.string
};

FallbackView.defaultProps = {
  isShowHeader: false,
  onClickHandler: null
};

export default memo(FallbackView);
